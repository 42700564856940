<template>
  <div style="display: flex;flex-wrap: wrap;padding: 32px 0 0 16px">
    <div v-for="(item, key, index) in remarks" :key="`goodsCoRemarks-${index}`" v-show="index <= viewGoodsRemarks" class="tw_process_group_list_item full">
      <div class="label">{{key | startCase}}</div>
      <div class="value" v-html="sanitize(item)" />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { getGoodsSerialIndex } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListPreviewGoodsLineCoItem',
  props: {
    selectGoodsLineIndex: Number,
  },
  inject: ['s'],
  data() {
    return {
    };
  },
  computed: {
    remarks() {
      return _.get(this.s.res, `linkageGoodsLineCo[${this.selectGoodsLineIndex}].remarksGrp`) || '';
    },
    viewGoodsRemarks() {
      return this.remarks ? getGoodsSerialIndex(this.remarks) : 0;
    },
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list_item {
    width: 50%;
    min-width: 300px;
    display: flex;
    margin-bottom: 12px;
    padding-right: 16px;

    &.full {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 24px;
    }

    .label {
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      vertical-align: top;
      padding-top: 2px;
    }

    .value {
      font-size: 14px;
      line-height: 20px;
      color: #3E3A39;
      vertical-align: top;
      flex: 1;
    }
  }

  .table_name {
    width: 100%;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: $color_gray_800;
    white-space: nowrap;
    border-top: 1px solid $color_gray_300;
    margin-bottom: 32px;
  }
</style>
